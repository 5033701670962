<template>
  <div v-if="!showDeniedMessage">
    <div>
      <transition name="slide-up" appear>
        <div class="talkArea">
          <div ref="messageContainer" class="messageRow">
            <el-button v-if="!noneHistory" type="info" size="small" plain @click="loadHistory()">{{ $t("Note.ShowMore") }}</el-button>
            <template v-for="i in messageList" :key="i">
              <template v-if="i.isDate">
                <div class="py-1 my-1 bg-secondary">{{ i.content }}</div>
              </template>
              <template v-else>
                <div :class="{ 'text-end': i.send, 'text-start': !i.send }">
                  <span class="messagePopup" :class="{ 'bg-dark': !i.send }">
                    <template v-if="hasImage(i.content) != []">
                      <img v-for="s in hasImage(i.content)" :key="s" class="w-50 resImage" :src="s" alt="" @click="openPreview(s)" />
                    </template>
                    <p class="pre-wrap" v-html="i.content.replace(/\[\*.*?\*\]/g, '')"></p>
                    <div v-if="!i.send" class="my-2 d-flex">
                      <el-button link><i class="fa-light fa-copy fa-lg" @click="copyResponse(i)"></i></el-button>
                      <template v-if="!i.isHistory">
                        <el-button link @click="isHelpful(true, i)"><i class="fa-light fa-thumbs-up fa-lg"></i></el-button>
                        <el-button link class="me-4" @click="isHelpful(false, i)"><i class="fa-light fa-thumbs-down fa-flip-horizontal fa-lg"></i></el-button>
                      </template>
                    </div>
                    <p class="mt-1" style="font-size: x-small">
                      {{ i.messageTime }}
                    </p>
                  </span>
                </div>
              </template>
            </template>
          </div>
        </div>
      </transition>
    </div>
    <div class="messageBox">
      <div v-if="splitLoadingList.length > 0" class="splitLoadingList">
        <div v-for="s in splitLoadingList" class="mb-1" :key="s">{{ s.noteId }}:<i class="ms-1 fa-sharp-duotone fa-solid fa-loader fa-spin-pulse"></i></div>
      </div>
      <div class="d-flex align-items-center">
        <div style="width: 100%">
          <div v-if="imageUrl" class="image-preview">
            <el-button class="deleteImg" circle @click="imageUrl = ''"> <i class="fal fa-times"></i></el-button>
            <img class="w-100" :src="imageUrl" alt="Pasted Image" />
          </div>
          <div class="message-main">
            <div class="message-input w-100" style="width: 100%">
              <el-input :placeholder="$t('Note.PleaseInputText')" type="textarea" class="message-input-textarea w-100" name="" id="" autosize resize="none" @paste="handlePaste" @keydown.enter="sendMessage" v-model="messageInput" />
              <el-button class="ms-2 fs-3" link @click="sendMessage"><i class="fad fa-arrow-circle-up fa-lg"></i></el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <transition name="fade">
      <div v-if="showDeniedMessage" class="denied-message">
        <div class="denied-content">
          <p>您無法使用此頁面，請聯繫管理員。</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import FingerprintJS from "@fingerprintjs/fingerprintjs";

export default {
  name: "Interlocution",
  props: ["identityCode"],
  data() {
    return {
      fingerPrintId: "",
      isSubmitting: false,
      messageList: [],
      lastMessageDate: "",
      messageInput: "",
      searchAll: [],
      noneHistory: false,
      lastDate: null,
      splitLoadingList: [],
      showDeniedMessage: false,
    };
  },
  async mounted() {
    const vm = this;

    const isAllowed = await vm.checkAllowOrigin();
    if (!isAllowed) {
      vm.showDeniedMessage = true;
      return;
    }

    vm.fingerPrintId = await vm.getFingerprint();
    await vm.loadHistory();
  },
  methods: {
    async checkAllowOrigin() {
      try {
        const res = await this.fetchAPI("get", `${process.env.VUE_APP_URL_API}/Note/GPT/CheckAllowOrigin?identityCode=${this.identityCode}`);
        return res.data;
      } catch (err) {
        return null;
      }
    },
    async getFingerprint() {
      try {
        const fp = await FingerprintJS.load();
        const result = await fp.get();
        console.log("Fingerprint ID:", result.visitorId);
        return result.visitorId;
      } catch (error) {
        console.error("FingerprintJS 加載失敗:", error);
        return "";
      }
    },
    openPreview(imageSrc) {
      window.open(imageSrc, "_blank");
    },
    hasImage(content) {
      let match = content.match(/\[\*(.*?)\*\]/);
      if (match) {
        let extractedContent = match[1];
        let urls = extractedContent.split(",").map(url => url.trim());
        urls = urls.filter(x => x.includes("http"));
        return urls;
      }
      return [];
    },
    async loadHistory() {
      let vm = this;
      const messageContainer = vm.$refs.messageContainer;
      const prevScrollHeight = messageContainer.scrollHeight;
      await vm
        .fetchAPI("post", `${process.env.VUE_APP_URL_API}/Note/GPT/GetHistory`, {
          FingerPrintId: vm.fingerPrintId,
          IdentityCode: vm.identityCode,
          EndDateTime: vm.lastMessageDate,
          Count: 10,
        })
        .then(res => {
          if (res.data.response.length == 0) {
            vm.$message({
              type: "info",
              message: "已無更早的資料!",
            });
            vm.noneHistory = true;
            return;
          }

          res.data.response.forEach((x, index) => {
            const messageDate = x.create_time.split(" ")[0]; // 取得日期部分
            const messageTime = x.create_time.split(" ")[1].slice(0, 5); // 取得 HH:mm 格式;
            if (index == 0) {
              vm.lastDate = messageDate;
            }
            // 如果日期不同，插入日期標籤
            if (vm.lastDate !== messageDate) {
              vm.messageList.unshift({
                id: `date_${messageDate}`,
                content: messageDate, // 顯示日期
                isDate: true, // 標記這是一個日期標籤
              });
              vm.lastDate = messageDate; // 更新 lastDate
            }
            vm.messageList.unshift({
              id: x.qa_id, //答
              content: x.a_text,
              send: false, // 標記這是發送的訊息
              isHistory: true,
              loading: false, // 這不是loading訊息
              isSaved: false,
              messageTime: messageTime,
            });
            vm.messageList.unshift({
              id: null, //問
              content: x.q_text, // 使用者發送的訊息
              send: true, // 標記這是發送的訊息
              loading: false, // 這不是loading訊息
              isSaved: false,
              isHistory: true,
              messageTime: messageTime,
            });
            if (index == res.data.response.length - 1) {
              vm.messageList.unshift({
                id: `date_${messageDate}`,
                content: messageDate, // 顯示日期
                isDate: true, // 標記這是一個日期標籤
              });
              vm.lastDate = messageDate; // 更新 lastDate
            }
          });
          // 獲取最後一筆資料
          const lastMessage = res.data.response.slice(-1)[0]; // 或者 vm.messageList[vm.messageList.length - 1];
          vm.lastMessageDate = lastMessage.create_time;
          vm.$nextTick(() => {
            const newScrollHeight = messageContainer.scrollHeight;
            // 將滾動位置調整回去
            messageContainer.scrollTop = newScrollHeight - prevScrollHeight;
          });
        });
    },
    async copyResponse(i) {
      let vm = this;
      try {
        // 假設 i 是要複製的文本
        await navigator.clipboard.writeText(i.content);
        // 這裡可以添加一個提示給用戶，比如使用 alert 或自定義的提示框
        vm.$message({
          type: "success",
          message: "複製成功!",
        });
      } catch (err) {
        console.error("複製失敗:", err);
        alert("複製失敗，請重試。");
      }
    },
    isHelpful(isisHelpful, i) {
      let vm = this;
      vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/Note/GPT/GPTHelpful`, {
        Id: i.id,
        IsHelpful: isisHelpful,
      }).then(res => {
        console.log(res);
        vm.$message({
          type: "success",
          message: "感謝回饋!",
        });
      });
    },
    typeWriter(message, targetMessage, index, vm, logId) {
      let i = 0;
      targetMessage.content = ""; // 清空content，準備逐字顯示
      let interval = setInterval(() => {
        if (i < message.length) {
          targetMessage.content += message.charAt(i); // 每次增加一個字符
          // 這裡我們要重新更新 messageList 內的對應項目
          vm.messageList.splice(index, 1, { ...targetMessage });
          i++;
        } else {
          clearInterval(interval); // 打字完成後清除計時器
          vm.messageList[index].id = logId; // 這裡更新ID
        }
        this.$nextTick(() => {
          const messageContainer = this.$refs.messageContainer;
          messageContainer.scrollTop = messageContainer.scrollHeight;
        });
      }, 10); // 每個字符的顯示速度，可以根據需要調整
    },
    handlePaste() {
      const items = (event.clipboardData || window.clipboardData).items;
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.type.startsWith("image/")) {
          const file = item.getAsFile();
          const reader = new FileReader();
          reader.onload = e => {
            this.imageUrl = e.target.result; // 更新圖片 URL
          };
          reader.readAsDataURL(file);
        }
      }
    },
    async sendMessage() {
      let vm = this;

      if (vm.isSubmitting) {
        vm.notify("info", `等待回應中，請稍後再試`);
        return;
      }
      if (vm.messageInput.trim() == "") {
        vm.notify("error", `尚未輸入訊息`);
        return;
      }

      let currentDate = new Date();
      let messageTime = currentDate.toLocaleTimeString("zh-TW", {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
      });

      // 1. 先顯示使用者發送的訊息
      vm.messageList.push({
        id: currentDate, // 使用唯一的ID
        content: vm.messageInput, // 使用者發送的訊息
        send: true, // 標記這是發送的訊息
        loading: false, // 這不是loading訊息
        isSaved: false,
        messageTime: messageTime,
      });
      // 2. 顯示一個loading氣泡，等待回應
      var loadingMessage = {
        id: currentDate + 1, // 生成唯一ID
        content: '<i class="fad fa-spinner fa-pulse"></i>', // 顯示 loading 中的提示
        send: false, // 標記這是回應的訊息
        loading: true, // 標記為loading狀態
        isSaved: false,
      };
      vm.messageList.push(loadingMessage); // 顯示loading氣泡

      this.$nextTick(() => {
        const messageContainer = this.$refs.messageContainer;
        messageContainer.scrollTop = messageContainer.scrollHeight;
      });

      // 檢查有沒有剩餘GPT則數
      var isEnoughGptPoint = await vm.checkEnoughGptPoint();
      if (!isEnoughGptPoint) {
        vm.notify("error", `由於則數上限或會籍到期，請聯絡客服進行充值處理！`);
        vm.isSubmitting = false;
        vm.messageList.splice(-2);
        return;
      }

      let data = {
        FingerPrintId: vm.fingerPrintId,
        TagIds: [],
        Content: vm.messageInput,
        IdentityCode: vm.identityCode,
      };
      vm.messageInput = "";
      vm.isSubmitting = true;
      vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/Note/GPT/Request`, data, false)
        .then(res => {
          // 先找到 messageList 中的對應項目
          let index = vm.messageList.indexOf(loadingMessage);
          if (index !== -1) {
            vm.typeWriter(res.data.content, loadingMessage, index, vm, res.data.gptLogId);
            loadingMessage.messageTime = new Date().toLocaleTimeString("zh-TW", {
              hour12: false,
              hour: "2-digit",
              minute: "2-digit",
            });
          }
        })
        .catch(err => {
          let index = vm.messageList.indexOf(loadingMessage);
          vm.notify("error", err);
          vm.typeWriter("回應失敗,請稍後再試!", loadingMessage, index, vm, null);
        })
        .finally(() => {
          vm.isSubmitting = false;
        });
    },
    async checkEnoughGptPoint() {
      try {
        const res = await this.fetchAPI("get", `${process.env.VUE_APP_URL_API}/Note/GPT/CheckEnoughGptPoint?identityCode=${this.identityCode}`);
        return res.data;
      } catch (err) {
        return null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.talkArea {
  position: fixed;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  padding-bottom: 120px;
}

.messageBox {
  position: fixed;
  padding: 10px;
  background-color: white;
  border-radius: 10px 10px 0 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 9;
  max-width: 700px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 3px 3px rgba(0, 0, 0, 0.15);
}

.message-main {
  display: flex;
  align-items: center;
  background-color: #cccccc;
  border-radius: 10px;

  .message-input {
    border-radius: 10px;
    padding: 20px 10px;
    background-color: #f5f5f5;
    display: flex;

    .message-input-textarea {
      border: none;
      outline: none;
      box-shadow: none;
      background-color: rgba(0, 0, 0, 0);

      .el-textarea__inner {
        background-color: rgba(0, 0, 0, 0) !important;
      }
    }

    .message-input-textarea:focus,
    .message-input-textarea:active {
      border: none;
      outline: none;
      box-shadow: none;
      background-color: rgba(0, 0, 0, 0);
    }
  }
}

.pre-wrap {
  white-space: pre-wrap;
}

.wrap-text {
  font-size: 14px;
  line-height: 1.5;
  word-break: break-word;
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}

:deep .el-textarea {
  textarea {
    border: none;
    padding: 0.5em 0;
    width: 100%;
    box-shadow: none !important;
    overflow: hidden;

    &:focus {
      outline: none;
      background-color: #f4f4f4;
    }
  }

  &.mobile textarea {
    overflow-y: auto;
  }
}

input[type="text"],
textarea {
  background-color: rgba(0, 0, 0, 0);
}

.fa-times {
  line-height: 1.8;
}

.colorCircle {
  width: 20px;
  height: 20px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

:deep .el-link {
  font-size: 16px;
}

.messageRow {
  margin: auto;
  max-width: 700px;
  padding: 1rem;
  overflow-y: auto;
  height: 100%;

  .messagePopup {
    max-width: 300px;
    margin: 5px 0;
    word-break: break-all;
    line-height: 1.5;
    box-shadow: 2px 2px rgba(0, 0, 0, 0.4);
    color: white;
    display: inline-block;
    padding: 0.75rem;
    border-radius: 8px;
    background-color: black;
    text-align: start;
  }
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.3s ease;
}

.slide-up-enter,
.slide-up-leave-to {
  transform: translateY(100%);
  opacity: 0;
}

.image-preview {
  width: 50px;
  border-radius: 8px;
  overflow: hidden;
  height: 50px;
  position: relative;
  margin-bottom: 0.5rem;
}

.image-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.image-preview .deleteImg {
  position: absolute;
  right: 2px;
  top: 2px;
  z-index: 11;
  width: 20px;
  line-height: 20px;
  height: 20px;
}

::v-deep .el-upload--picture-card {
  width: 60px;
  height: 20px;
  line-height: 80px;
}

::v-deep .el-upload--picture-card i {
  font-size: unset;
}

::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 80px !important;
  height: 80px !important;
}

.splitLoadingList {
  position: absolute;
  right: 10px;
  top: -50px;
  background-color: white;
  box-sizing: border-box;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  z-index: 66;
  box-shadow: 3px 3px rgba(0, 0, 0, 0.1);
}

.resImage {
  height: 138px;
}

.denied-message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #ffffff; /* 全屏白色背景 */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* 确保覆盖所有内容 */
}

.denied-content {
  background: rgba(0, 0, 0, 0.9); /* 让背景黑色但稍微透明 */
  padding: 20px 40px;
  border-radius: 8px;
  font-size: 18px;
  text-align: center;
  color: white; /* 文字颜色改成白色，适配黑色背景 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
